
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import LuSpinner from "@/components/Lu/LuSpinner.vue";
import TableInfo from "@/components/TableInfo.vue";
import Pagination from "@/components/Pagination.vue";
import Select from "@/components/Form/Select.vue";
import { IPagingData } from "@/interfaces/IPagingData";

@Options({
  components: {
    Pagination,
    TableInfo,
    Select,
    LuSpinner,
  },
  props: {
    /**
     * Gets data of the rows
     */
    rows: {
      type: Array as PropType<unknown[]>,
      required: true,
    },
    /**
     * If allow sort
     */
    allowSort: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * The current sort column
     */
    sortColumn: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the sort order is ascending
     */
    ascending: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * If the first column always should be visible
     */
    stickyFirstColumn: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the head always should be visible
     */
    stickyHead: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * A list of column names that should not be visible
     */
    hiddenColumns: {
      type: Array as PropType<string[]>,
      required: false,
      default: ["id"],
    },
    /**
     * If the table should include a pagination
     */
    showPagination: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the student should be able to change number of rows per page
     */
    allowChangeRowPerPage: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * If the table should tell how many rows is showing and the total number of rows
     */
    showNumRows: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Columns that should not be sortable
     */
    unsortableColumns: {
      type: Array as PropType<string[]>,
      required: false,
      default: [],
    },
    /**
     * Paging data such as the current page in the table, total number of pages, number of rows per page
     */
    pagingData: {
      type: Object as PropType<IPagingData>,
      required: false,
    },
    /**
     * A page heading to be displayed above the table
     */
    pageHeading: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * Text to show when no data is populated
     */
    noDataText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * Whether or not to show a spinner when data is loading
     */ useSpinner: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Steers when to show the loading spinner
     */ isLoading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * Whether or not to override the default column width calculation
     * If this is set to true, the column width will be set to auto
     */
    overrideColWidthCalculation: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data: () => ({
    /**
     * The rows per page options
     */
    pageSizeOptions: [5, 10, 25, 50],
    /**
     * Used to delay the display of the spinner if being used
     */
    showSpinner: false,
    /**
     * The width of all columns except the last
     */
    colWidthForAll: 0,
  }),
  computed: {
    /**
     * Visible column names
     */
    cols(): string[] {
      if (!this.rows || this.rows.length < 1) {
        return [];
      }
      let cols: string[] = [];
      Object.getOwnPropertyNames(this.rows[0]).forEach((key) => {
        if (!this.hiddenColumns.includes(key)) {
          cols.push(key);
        }
      });
      return cols;
    },
    /**
     * The width in percentage of all columns except the last,
     * but will return auto if overrideColWidthCalculation is set to true
     */
    colWidth(): string {
      this.colWidthForAll = Math.round(100 / this.cols.length);
      return this.getColWidthString(this.colWidthForAll);
    },
    /**
     * The width in percentage of the last column,
     * but will return auto if overrideColWidthCalculation is set to true.
     * Because colWidth is rounded, lastColWidth takes up any slack so that the total width of all columns is 100
     */
    lastColWidth(): string {
      const colWidth =
        this.colWidthForAll + (100 - this.colWidthForAll * this.cols.length);
      return this.getColWidthString(colWidth);
    },
    /**
     * Whether rows of data have loaded
     */
    rowsHaveLoaded(): boolean {
      return this.rows.length > 0;
    },
  },
  methods: {
    /**
     * Delays display of the loading spinner for 500 milliseconds so as not to cause
     * jumps in the table display when sort column or direction, filter, etc changes.
     * It is called from the watch block
     */
    delaySpinnerDisplay(): void {
      if (this.useSpinner) {
        this.showSpinner = false;
        this.sleep(500).then(() => {
          this.showSpinner = true;
        });
      }
    },
    /**
     * Uses setTimeout to return a promise that can be used in the calling code to delay execution of the code
     *
     * @param {number} milliseconds The number of milliseconds to sleep for
     */
    sleep(milliseconds: number): Promise<unknown> {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    /**
     * Capitalizes a string
     *
     * @param {string} str The string that should be capitalized
     * @returns {string} The capitalized string
     */
    capitalize(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    /**
     * Sorts the table based on the given column name and ascending properties
     *
     * @param {string} columnName The column the sort should be based on
     */
    sortTable(columnName: string): void {
      if (this.allowSort) {
        let ascending = this.sortColumn === columnName ? !this.ascending : true;
        this.$emit("sortChanged", columnName, ascending);
      }
    },
    /**
     * Gets the rows that should be shown on the current page
     */
    getRows(): Record<string, string | number>[] {
      return this.rows;
    },
    /**
     * Gets called when the user clicks on a row
     * Emits a rowClick event with information about the row
     *
     * @param {number} rowIndex The index of the clicked row
     * @param {unknown} rowData The data of the clicked row
     */
    onRowClick(rowIndex: number, rowData: Array<unknown>): void {
      this.$emit("rowClick", { rowIndex, rowData });
    },
    /**
     * Gets called when the user clicks on a cell
     * Emits a cellClick event with information about the row and cell
     *
     * @param {number} rowIndex The row index of the clicked cell
     * @param {unknown} rowData The row data of the clicked cell
     * @param {string} cellName The name of the clicked cell
     */
    onCellClick(
      rowIndex: number,
      rowData: Array<unknown>,
      cellName: string
    ): void {
      this.$emit("cellClick", { rowIndex, rowData, cellName });
    },
    onPageNumberChange(pageNumber: number): void {
      this.$emit("pageNumberChanged", pageNumber);
    },
    /**
     * Gets called when the user changes page size
     */
    onPageSizeChange(): void {
      this.$emit("pageSizeChanged", this.pagingData.pageSize);
    },
    /**
     * Gets the width of a column based on the given column width and the overrideColWidthCalculation property
     */
    getColWidthString(colWidth: number): string {
      return this.overrideColWidthCalculation ? "auto" : `${colWidth}%`;
    },
  },
  watch: {
    /**
     * Gets called when page changes
     *
     * @param {number} page The new page number
     */
    currentPage(page: number): void {
      this.$emit("pageChange", page);
    },
    /**
     * Gets called when filters or sort column name or ascending changes
     */
    rows() {
      this.delaySpinnerDisplay();
    },
    sortColumn() {
      this.delaySpinnerDisplay();
    },
    ascending() {
      this.delaySpinnerDisplay();
    },
  },
  mounted() {
    this.delaySpinnerDisplay();
  },
})
export default class Table extends Vue {}
