import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62665941"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "aria-label": "Pagination",
  class: "m-0"
}
const _hoisted_2 = { class: "pagination pagination-sm" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "page-icon" }
const _hoisted_5 = { class: "page-label" }
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["aria-label"]
const _hoisted_9 = { class: "page-label" }
const _hoisted_10 = { class: "page-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "keydownElement",
    tabindex: "0",
    onKeydown: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleKeydown($event))),
    class: "pagination-container"
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", {
          class: _normalizeClass(["page-item", !_ctx.pagingData.hasPrevious ? 'disabled' : ''])
        }, [
          _createElementVNode("a", {
            class: "page-link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.pagingData.currentPage > 1
                ? _ctx.changePageNumber(_ctx.pagingData.currentPage - 1)
                : null
            )),
            "aria-label": _ctx.$t('pagination.goto-previous')
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, { icon: "chevron-left" })
            ]),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("pagination.previous")), 1)
          ], 8, _hoisted_3)
        ], 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageNumbers, (pageNum, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["page-item", pageNum === _ctx.pagingData.currentPage ? 'active' : '']),
            key: index,
            "aria-label": 
            pageNum === _ctx.pagingData.currentPage
              ? _ctx.$t('pagination.current')
              : _ctx.$t('pagination.goto', { page: pageNum })
          
          }, [
            _createElementVNode("a", {
              class: _normalizeClass(pageNum ? 'page-link' : 'page-text'),
              onClick: ($event: any) => (pageNum ? _ctx.changePageNumber(pageNum) : null)
            }, [
              _createElementVNode("span", null, _toDisplayString(pageNum), 1),
              (!pageNum)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: "ellipsis-h"
                  }))
                : _createCommentVNode("", true)
            ], 10, _hoisted_7)
          ], 10, _hoisted_6))
        }), 128)),
        _createElementVNode("li", {
          class: _normalizeClass(["page-item", !_ctx.pagingData.hasNext ? 'disabled' : ''])
        }, [
          _createElementVNode("a", {
            class: "page-link",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (
              _ctx.pagingData.currentPage < _ctx.pagingData.totalPages
                ? _ctx.changePageNumber(_ctx.pagingData.currentPage + 1)
                : null
            )),
            "aria-label": _ctx.$t('pagination.goto-next')
          }, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("pagination.next")), 1),
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
            ])
          ], 8, _hoisted_8)
        ], 2)
      ])
    ])
  ], 544))
}