import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-993c70b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["disabled", "required", "value", "aria-label"]
const _hoisted_5 = ["selected"]
const _hoisted_6 = ["value", "selected"]
const _hoisted_7 = {
  key: 0,
  class: "form-text text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("select", {
        class: "form-control custom-select",
        disabled: _ctx.disabled,
        required: _ctx.required,
        value: _ctx.value,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event.target.value))),
        "aria-label": _ctx.label ? _ctx.label : '',
        ref: "input",
        onInvalid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validityMessage ? _ctx.setValidityMessage(_ctx.validityMessage) : null))
      }, [
        (_ctx.placeholder)
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              value: "",
              disabled: "",
              selected: !_ctx.options.includes(_ctx.value)
            }, _toDisplayString(_ctx.placeholder), 9, _hoisted_5))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            key: option,
            value: option,
            selected: option === _ctx.value
          }, [
            _renderSlot(_ctx.$slots, "default", { value: option }, () => [
              _createTextVNode(_toDisplayString(option), 1)
            ], true)
          ], 8, _hoisted_6))
        }), 128))
      ], 40, _hoisted_4)
    ]),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true)
  ]))
}