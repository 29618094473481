import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-297f8a7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-nowrap justify-content-between align-items-center flex-grow-0" }
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-wrap justify-content-center align-items-center flex-grow-1"
}
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = {
  key: 3,
  class: "d-flex flex-nowrap justify-content-between align-items-center flex-grow-0"
}
const _hoisted_5 = {
  key: 1,
  style: {"text-align":"center"}
}
const _hoisted_6 = {
  key: 2,
  class: "table table-striped"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  style: {"margin-left":"0.5rem"},
  class: "sort-column"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 3,
  class: "d-flex flex-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Select = _resolveComponent("Select")!
  const _component_TableInfo = _resolveComponent("TableInfo")!
  const _component_LuSpinner = _resolveComponent("LuSpinner")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showPagination && _ctx.rowsHaveLoaded)
        ? (_openBlock(), _createBlock(_component_Pagination, {
            key: 0,
            class: "mr-auto",
            pagingData: _ctx.pagingData,
            shouldFocus: true,
            onPageNumberChanged: _ctx.onPageNumberChange
          }, null, 8, ["pagingData", "onPageNumberChanged"]))
        : _createCommentVNode("", true),
      (_ctx.pageHeading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", {
              class: "page-heading",
              "aria-label": _ctx.pageHeading
            }, _toDisplayString(_ctx.pageHeading), 9, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isLoading === false)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass({ 'mr-3 mb-1': _ctx.$slots.actions })
          }, [
            _renderSlot(_ctx.$slots, "actions", { class: "pr-3" }, undefined, true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.rowsHaveLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.allowChangeRowPerPage)
              ? (_openBlock(), _createBlock(_component_Select, {
                  key: 0,
                  options: _ctx.pageSizeOptions,
                  value: _ctx.pagingData.pageSize,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pagingData.pageSize) = $event)),
                  onChange: _ctx.onPageSizeChange
                }, null, 8, ["options", "value", "onChange"]))
              : _createCommentVNode("", true),
            (_ctx.showNumRows)
              ? (_openBlock(), _createBlock(_component_TableInfo, {
                  key: 1,
                  class: "ml-2",
                  pagingData: _ctx.pagingData
                }, null, 8, ["pagingData"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.useSpinner && _ctx.isLoading && _ctx.showSpinner)
      ? (_openBlock(), _createBlock(_component_LuSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.rowsHaveLoaded === false && _ctx.isLoading === false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.noDataText || _ctx.$t("table.noData")), 1))
      : _createCommentVNode("", true),
    (_ctx.cols.length > 0)
      ? (_openBlock(), _createElementBlock("table", _hoisted_6, [
          _createElementVNode("thead", {
            class: _normalizeClass(["thead-dark", { 'sticky-head': _ctx.stickyHead }])
          }, [
            _createElementVNode("tr", null, [
              (_ctx.$slots.rowActionStart)
                ? (_openBlock(), _createElementBlock("th", _hoisted_7))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col, colIndex) => {
                return (_openBlock(), _createElementBlock("th", {
                  scope: "col",
                  key: col,
                  onClick: ($event: any) => (!_ctx.unsortableColumns.includes(col) && _ctx.sortTable(col)),
                  style: _normalizeStyle({
              cursor:
                _ctx.allowSort && !_ctx.unsortableColumns.includes(col)
                  ? 'pointer'
                  : 'auto',
              width: colIndex === _ctx.cols.length - 1 ? _ctx.lastColWidth : _ctx.colWidth,
            }),
                  class: _normalizeClass({
              'sticky-col': _ctx.stickyFirstColumn && colIndex === 0,
              'sort-column': _ctx.sortColumn === col,
            })
                }, [
                  _renderSlot(_ctx.$slots, "td", { col: col }, () => [
                    _createTextVNode(_toDisplayString(_ctx.capitalize(col)), 1)
                  ], true),
                  _createElementVNode("span", _hoisted_9, [
                    (_ctx.sortColumn === col && _ctx.ascending)
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 0,
                          icon: "caret-up"
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.sortColumn === col && !_ctx.ascending)
                      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                          key: 1,
                          icon: "caret-down"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ], 14, _hoisted_8))
              }), 128)),
              (_ctx.$slots.rowActionEnd)
                ? (_openBlock(), _createElementBlock("th", _hoisted_10))
                : _createCommentVNode("", true)
            ])
          ], 2),
          _createElementVNode("tbody", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRows(), (row, rowIndex) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: row,
                onClick: ($event: any) => (_ctx.onRowClick(rowIndex, row))
              }, [
                (_ctx.$slots.rowActionStart)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                      _renderSlot(_ctx.$slots, "rowActionStart", { row: row }, undefined, true)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col, colIndex) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: col,
                    class: _normalizeClass({
              'sticky-col': _ctx.stickyFirstColumn && colIndex === 0,
            }),
                    onClick: ($event: any) => (_ctx.onCellClick(rowIndex, row, col))
                  }, [
                    _renderSlot(_ctx.$slots, "default", {
                      row: row,
                      col: col,
                      val: row[col]
                    }, () => [
                      _createTextVNode(_toDisplayString(row[col]), 1)
                    ], true)
                  ], 10, _hoisted_14))
                }), 128)),
                (_ctx.$slots.rowActionEnd)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                      _renderSlot(_ctx.$slots, "rowActionEnd", { row: row }, undefined, true)
                    ]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_12))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.rowsHaveLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          (_ctx.showPagination)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                class: "mr-auto",
                pagingData: _ctx.pagingData,
                onPageNumberChanged: _ctx.onPageNumberChange
              }, null, 8, ["pagingData", "onPageNumberChanged"]))
            : _createCommentVNode("", true),
          (_ctx.allowChangeRowPerPage)
            ? (_openBlock(), _createBlock(_component_Select, {
                key: 1,
                options: _ctx.pageSizeOptions,
                value: _ctx.pagingData.pageSize,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagingData.pageSize) = $event)),
                onChange: _ctx.onPageSizeChange
              }, null, 8, ["options", "value", "onChange"]))
            : _createCommentVNode("", true),
          (_ctx.showNumRows)
            ? (_openBlock(), _createBlock(_component_TableInfo, {
                key: 2,
                class: "ml-2",
                pagingData: _ctx.pagingData
              }, null, 8, ["pagingData"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}